import React, {SVGAttributes} from 'react';
import LogoIcon from "@app/components/icons/logo-icon";
import {useTranslation} from "next-i18next";
import EndToEndIcon from "@app/components/icons/features/end-to-end-icon";

interface featureDto {
    title: string;
    subtitle: string;
    icon: (props: SVGAttributes<{}>) => JSX.Element
}

interface IFeatureCard {
    feature: featureDto
}

function FeatureCard({feature}: IFeatureCard) {
    const {t} = useTranslation();
    const Icon = feature.icon;
    return (
        <div
            className="px-8 py-10 sm:w-[400px] w-full h-[280px] flex flex-col bg-white rounded-[16px] items-center ">
            <Icon height={80} width={80}/>
            <p className="mt-4 h4 mb-1 text-black-800">
                {t(feature.title)}
            </p>
            <p className="p2 text-black-700">
                {t(feature.subtitle)}
            </p>
        </div>
    );
}

export default FeatureCard;

import React from 'react';

function GrondslaganIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg height="800px" width="800px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" {...props}
             xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 512 512" xmlSpace="preserve">
            {/*<style type="text/css">*/}
            {/*    .st0{fill:#000000;}*/}
            {/*</style>*/}
            <g>
                <path className="st0" d="M511.958,95.923c-0.024-9.025-1.274-16.916-3.912-23.013C388.773,17.43,337.015,89.091,256,89.091
                    c-81.016,0-132.773-71.66-252.046-16.18c-18,41.6,26.751,165.761,108.015,177.971c92.265,13.88,112.523-60.096,144.031-60.096
                    c31.516,0,51.766,73.976,144.031,60.096c29.746-4.466,54.57-23.956,73.24-48.879v258.151H512V95.923H511.958z M90.453,136.844
                    c26.768-18.529,72.082-26.759,113.268,20.606C158.416,192.448,98.692,175.979,90.453,136.844z M308.287,157.45
                    c41.178-47.365,86.492-39.135,113.26-20.606C413.308,175.979,353.584,192.448,308.287,157.45z"/>
            </g>
        </svg>
    );
}

export default GrondslaganIcon;

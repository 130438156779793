import EndToEndIcon from "@app/components/icons/features/end-to-end-icon";
import GrondslaganIcon from "@app/components/icons/features/grondslagan-icon";
import DragAndDropIcon from "@app/components/icons/features/drag-and-drop-icon";
import SingleTenant from "@app/components/icons/features/single-tenant";
import EmailIcon from "@app/components/icons/features/emailIcon";

export const features = [
    {
        "title": "landingPage.features.01.title",
        'subtitle': "landingPage.features.01.description",
        'icon': EndToEndIcon
    },
    {
        "title": "landingPage.features.02.title",
        'subtitle': "landingPage.features.02.description",
        'icon': GrondslaganIcon
    },
    {
        "title": "landingPage.features.03.title",
        'subtitle': "landingPage.features.03.description",
        'icon': DragAndDropIcon
    },
    {
        "title": "landingPage.features.04.title",
        'subtitle': "landingPage.features.04.description",
        'icon': SingleTenant
    },
    {
        "title": "landingPage.features.05.title",
        'subtitle': "landingPage.features.05.description",
        'icon': EmailIcon
    },
];

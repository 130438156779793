import React from 'react';

function SingleTenant(props: React.SVGAttributes<{}>) {
    return (
        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1" {...props}
             preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>organization-line</title>
            <polygon
                points="9.8 18.8 26.2 18.8 26.2 21.88 27.8 21.88 27.8 17.2 18.8 17.2 18.8 14 17.2 14 17.2 17.2 8.2 17.2 8.2 21.88 9.8 21.88 9.8 18.8"
                className="clr-i-outline clr-i-outline-path-1"></polygon>
            <path d="M14,23H4a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V25A2,2,0,0,0,14,23ZM4,31V25H14v6Z"
                  className="clr-i-outline clr-i-outline-path-2"></path>
            <path d="M32,23H22a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V25A2,2,0,0,0,32,23ZM22,31V25H32v6Z"
                  className="clr-i-outline clr-i-outline-path-3"></path>
            <path d="M13,13H23a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H13a2,2,0,0,0-2,2v6A2,2,0,0,0,13,13Zm0-8H23v6H13Z"
                  className="clr-i-outline clr-i-outline-path-4"></path>
            <rect x="0" y="0" width="36" height="36" fillOpacity="0"/>
        </svg>
    );
}

export default SingleTenant;

import React, {useEffect} from 'react';
import {getDomainFromUrl} from "@app/utils/stringUtils";
import environments from "@app/configs/environments";
import RestrictImage from "@app/assets/image/restrict.png";
import Image from "next/image";
import Button from "@app/components/ui/button";
import {useLazyGetAuthUrlQuery} from "@app/store/login/api";
import {statusErrorMessageHandler} from "@app/utils/errorUtils";
import {useRouter} from "next/router";
import Cookies from "js-cookie";
import {useTranslation} from "next-i18next";
import AnchorLink from "@app/components/ui/links/anchor-link";

function Error({email}: { email: string }) {
    const {t} = useTranslation(["common"]);
    Cookies.set('restrictEmail', email, {expires: 0});
    const [getAuthUrl, data] = useLazyGetAuthUrlQuery();
    const router = useRouter();
    if (data.isError) {
        // @ts-ignore
        statusErrorMessageHandler(data?.error.originalStatus || data?.error.status, t)
    }

    useEffect(() => {
        if (data.isSuccess) {
            router.push(data.data.authorizationUrl);
        }
    }, [data.isSuccess]);

    const handleOnClick = async () => {
        try {
            await getAuthUrl(router.locale ?? "en");
        } catch (e: any) {
            console.error('error: ', e);
        }
    };
    return (
        <div className="h-screen flex flex-col w-full lg:px-20 gap-5 px-5 items-center justify-center">
            <Image height={300} width={300} className=" object-cover bg-cover" src={RestrictImage}
                   alt="restrict Image"/>
            <p className="h2">{t('errorPage.title')}</p>
            <p dangerouslySetInnerHTML={{
                __html: t("errorPage.description", {
                    email,
                    domain: getDomainFromUrl(environments.VIRTUAL_HOST)
                })
            }} className="p1 text-black-2 max-w-[500px] text-center"></p>
            <Button onClick={handleOnClick} className="rounded-[4px] px-8 py-6">{t('signIn')}</Button>
            <p className="text-black-700">{t('errorPage.contact')} <AnchorLink className="text-blue-500"
                                                                               href="mailto:pdfeditor200@gmail.com">{t('errorPage.supportTeam')}</AnchorLink>
            </p>
        </div>
    );
}

export default Error;
